





























































































































































































































































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { calcPrognWithForm, IFormBip } from '@/modules/budget-request/components/js/budgetCalcProgn';
import CBudgetAgreementModal, { iAgrBtns } from '@/modules/budget-request/components/budget-sum-calc/budget-agreement-modal.vue';
import CBudgetAgreementHistModal from '@/modules/budget-request/components/budget-sum-calc/budget-agreement-hist-modal.vue';
import CBudgetUserSignModal from '@/modules/budget-request/components/budget-sum-calc/budget-user-sign-modal.vue';
import CBudgetUserStartSignModal from '@/modules/budget-request/components/budget-sum-calc/budget-user-start-sign-modal.vue';
import CBudgetUserSignRecalModal from '@/modules/budget-request/components/budget-sum-calc/budget-user-sign-recal-modal.vue';
import CBudgetUserSignLogModal from '@/modules/budget-request/components/budget-sum-calc/budget-user-sign-log-modal.vue';
import store from '@/services/store';
import { makeToast } from '@/modules/budget-request/components/js/some-functions';
import { formLst } from '@/modules/budget-request/components/js/budgetFormsLst';
import NCAlayerService from '@/services/ncalayer';
import * as CryptoJS from 'crypto-js';

@Component({
    name: 'c-calc-prog-gu-tab',
    components: {
        'c-budg-agr-modal': CBudgetAgreementModal,
        'c-budg-agr-hist-modal': CBudgetAgreementHistModal,
        'c-sign-modal': CBudgetUserSignModal,
        'c-sign-recal-modal': CBudgetUserSignRecalModal,
        'c-sign-log-modal': CBudgetUserSignLogModal,
        'c-start-sign-modal': CBudgetUserStartSignModal
    }
})
export default class CBudgetSumCalcProgGuTable extends Vue {
    @Prop({
        required: true,
        default: 0
    })
    private curYearProp!: number;

    @Prop({
        required: true,
        default: null
    })
    private curAbpProp!: any | null;

    @Prop({
        required: true,
        default: null
    })
    private curDataTypeProp!: any | null;

    @Prop({
        required: true,
        default: null
    })
    private regionProp!: any | null;

    @Prop({
        required: true,
        default: null
    })
    private curVariantProp!: any | null;

    @Prop({
        required: true,
        default: null
    })
    private curGuProp!: any | null;

    @Prop({
        required: true,
        default: null
    })
    private variantEndDateProp!: any | null;

    private curGu: any | null = null;
    private curYear: number = 0;
    private curAbp: any = null;
    private curDataType: any = null;
    private curRegion: any = null;
    private curVariant: any = null;
    private variantEndDate: any | null = null;

    private get region() {
        if (this.curRegion) { return this.curRegion.code; }
        return null;
    }

    private progress = 0;
    private calcData: any[] = [];
    private collapseAll = true;
    private limit: any = { sum: 0, limit: 0 };
    private totalSum: any = { 'kass_ras': 0, 'fact_ras': 0, 'utoch_plan': 0, prog1: 0, prog2: 0, prog0: 0, limit0: 0, limit1: 0, limit2: 0 };


    private readonly signOperation = 'ecp_sign_gu';
    private signOperEnable = false; // доступность операции подписания
    private userSignEnable = false; // доступность подписания для пользователя
    private signUserLst: any[] = []; // список всех подписантов
    private curUserSigned: any | null = null; // подписан ли текущий пользователь
    private signStatus: {id: number, name: string} | null = null; // стутус подписания ( 0 не подписан - ни одной подписи; 1 частично подписан - подписи есть, но не все; 2- полностью подписан - все подписи имеются.)
    private totalSumWithoutBip: any = { prog1: 0, prog2: 0, prog0: 0 }; // сумма totalSum без БИП для подписания

    private checkedAll = false;

    // доступность согласования
    private agrEnabled = false;

    private readonly modeCode = 'bp';
    private operationCode: string[] = []; // операции согласования
    private operationSignCode: string[] = []; // операции подписания

    private agrBtnLst: iAgrBtns = { back: [], forward: [] };
    private selectArr: any = [];

    private mounted() {
        this.chgSignAcces();
        this.curAbp = this.curAbpProp;
        this.curYear = this.curYearProp;
        this.curDataType = this.curDataTypeProp;
        this.curRegion = this.regionProp;
        this.curVariant = this.curVariantProp;
        this.curGu = this.curGuProp;
        this.variantEndDate = this.variantEndDateProp;

        this.setAgrEnabled();
        // await this.loadOperations();
        this.loadData();
        this.$watch('curVariant', () => {
            this.setAgrEnabled();
        });
        this.$watch('usrId', () => {
            if (!this.usrId) { return; }
            this.loadOperations();
        })
    }

    private async loadOperations() {
        this.operationCode = [];
        this.operationSignCode = [];
        if (this.usrId === null) { return; }
        let result = null;
        try {
            result = await fetch(encodeURI(`/api/um/module/link?user=${this.usrId}&modulecode=004.002.002.002`));
            if (result.status === 200) {
                result = await result.json();
            }  else {
                if (result.status !== 404) { makeToast(this, 'danger', 'Ошибка загрузки операций пользователя', `${result.status}  ${result.statusText}`); }
                return;
            }
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка загрузки операций пользователя', (error as Error).toString());
            return;
        }
        if (result.operations) {
            for (const el of result.operations) {
                if (el.startsWith('ecp_')) {
                    this.operationSignCode.push(el);
                } else {
                    this.operationCode.push(el);
                }
            }
        }
        this.$emit('chgAgrAccess', this.operationCode.length > 0);
    }

    private get usrId(): string | null {
        if (!store.state.user.sub) { return null; }
        return store.state.user.sub;
    }

    private get usrName(): string | null {
        // return 'abab80c6-525b-4151-9499-a1ff21a157d2'; // ----- временнно!!!!!!!!
        if (!store.state.user.preferred_username) { return null; }
        return store.state.user.preferred_username;
    }

    private setAgrEnabled() {
        if (this.curVariant && this.curVariant.attribute && !this.curVariant.status) {
            this.agrEnabled = true;
        } else {
            this.agrEnabled = false;
        }
    }

    private chgEvent(params: any) {
        this.curAbp = params.curAbp;
        this.curYear = params.curYear;
        this.curDataType = params.curDataType;
        this.curRegion = params.regionProp;
        this.curVariant = params.curVariant;
        this.curGu = params.curGu;
        this.variantEndDate = params.variantEndDate;
        this.loadData();
    }

    private zeroDataDelete(result: any[], dataName: string) {
        for (let i = 0; i < result.length; i++) {
            const el = result[i];
            if (dataName === 'expens-prog') {
                if (!el.fact_ras && !el.kass_ras && !el.utoch_plan) {
                    result.splice(i, 1);
                    i--;
                }
            } else if (dataName === 'total-prog') {
                if (!el.limit0 && !el.limit1 && !el.limit2 && !el.value) {
                    result.splice(i, 1);
                    i--;
                }
            }
        }
    }

    // загрузка данных
    private async loadData() {
        this.checkedAll = false;
        this.signStatus = null;
        this.signUserLst = [];
        this.curUserSigned = null;
        this.userSignEnable = false;
        this.signOperEnable = false;
        this.calcData = [];
        this.totalSum = { 'kass_ras': 0, 'fact_ras': 0, 'utoch_plan': 0, prog1: 0, prog2: 0, prog0: 0, limit0: 0, limit1: 0, limit2: 0 };
        if (!this.curAbp || !this.curDataType || !this.curYear || !this.curVariant) {  return;  }
        this.chgSignAcces();
        this.collapseAll = true;
        this.calcData = [];
        this.progress = 30;

        const versYear =  ((this.curDataType && this.curDataType.code == '2') ? this.curVariant.year: null);
        const params = JSON.stringify({ usrId: this.usrId, curYear: parseInt(this.curYear.toString()), abp: this.curAbp.abp, dataType: this.curDataType.code, region: this.region, variant: this.curVariant.variant_uuid, curGu: (this.curGu? this.curGu.code: null), date_b: this.curVariant.date_start, date_e: this.variantEndDate, locale: this.$i18n.locale, vers_year: versYear });
        this.loadSign();
        const result = await Promise.all([
            this.loadConsExpProg(params),
            this.loadTotalProgGu(params),
            this.loadTotalAgreement(),
            this.loadLimitProg(params),
            this.loadNoteStatus(params)
        ]);
        if (result[0] === null || result[1] === null) {
            this.progress = 0;
            return; 
        }
        this.progress = 95;
        this.getTotalSumWithoutBip(result[1]);
        const mapConsol = this.createTreeMap(result[0], true);
        const newMap = this.createTreeMap(result[1], false);
        const noteStatusMap = this.getNoteStatusMap(result[4]);

        const agreementMap: any = result[2];
        const limitMap = result[3];
        this.getCalcData(newMap, agreementMap, mapConsol, limitMap, noteStatusMap);
    }

    private getNoteStatusMap(noteStatus: any[]) {
        const resMap = new Map();
        for (const el of noteStatus) {
            let guMap = resMap.get(el.gu);
            if (!guMap) { 
                guMap = new Map();
                resMap.set(el.gu, guMap);
            }
            guMap.set(el.prg, el);
        }
        return resMap;
    }

    // загрузка статуса пояснительно записки
    private async loadNoteStatus(params: any) {
        let response: any = [];
        try {
            response = await fetch(`/api-py/get-budget-total-gu-note-status/${encodeURI(params)}`);
            if (response.status === 200) {
                response = await response.json();
                return response;
            } else {
                makeToast(this, 'danger', 'Ошибка загрузки get-budget-total-gu-note-status', `${response.status} - ${response.statusText}`);
            }
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка загрузки get-budget-total-gu-note-status', (error as Error).toString());
        }
        return [];
    }

    /**
     * расчет суммы без бипов для подписания
     * @param dataLst
     */
    private getTotalSumWithoutBip(dataLst: any[]) {
        this.totalSumWithoutBip = { prog1: 0, prog2: 0, prog0: 0 };
        for (const el of dataLst) {
            if (el.bipCode == null && el.value) {
                const indx = el.year - this.curYear;
                this.totalSumWithoutBip[`prog${indx}`] += el.value;
            }
        }
    }

    /**
      * подгрузка суммы лимитов для программ из budget_limit_gu
      */
      private async loadLimitProg(params: any) {
        if (!this.curAbp || !this.curVariant || !this.curYear || !this.curGu) {
            return new Map();
        }

        let response: any = [];
        try {
            response = await fetch(`/api-py/get-limit-budget-sum-gu/${encodeURI(params)}`);
            if (response.status === 200) {
                response = await response.json();
                if (response.status === 'success') {
                    response = response.data;
                } else {
                    const typeErr = (response.status === 'warning') ? 'warning': 'danger';
                    makeToast(this, typeErr, 'get-limit-budget-sum-gu', `${response.statusText}`);
                    response = [];
                }
            } else {
                makeToast(this, 'danger', 'Ошибка загрузки get-limit-budget-sum-gu', `${response.status} - ${response.statusText}`);
                response = [];
            }
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка загрузки get-limit-budget-sum-gu', (error as Error).toString());
        }
        return this.createLimitTreeMap(response);
    }

    private createLimitTreeMap(limitLst: any[]) {
        const resMap = new Map();
        for (const el of limitLst) {
            let tmp = resMap.get(el.gu.gu);
            if (tmp) {
                tmp.push(el);
            } else {
                tmp = [el];
            }
            resMap.set(el.gu.gu, tmp);
        }
        for (const [keyRes, valueRes] of resMap) {
            const progMap = new Map();
            for (const el of valueRes) {
                progMap.set(el.prg.prg, el);
            }
            resMap.set(keyRes, progMap);
        }
        return resMap;
    }

    /**
     * загрузка из totalProg
     */
    private async loadTotalProgGu(params: any) {
        let result = [];
        try {
            const response: any = await fetch(`/api-py/get-req-total-prog-gu/${encodeURI(params)}`);
            if (response.status === 200) {
                result = await response.json();
                if (result.status === 'success') {
                    result = result.data;
                } else {
                    const typeErr = (result.status === 'warning') ? 'warning': 'danger';
                    makeToast(this, typeErr, 'get-req-total-prog-gu', result.statusText);
                    return null;
                }
            } else {
                makeToast(this, 'danger', 'Ошибка get-req-total', `${response.status} ${response.statusText}`);
                return null;
             }
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка get-req-total', (error as Error).toString());
            return null;
        }
        this.zeroDataDelete(result, 'total-prog');
        this.progress+=25;
        return result;
    }

    /**
     * загрузка budget-consolidate-expens-prog-gu
     */
    private async loadConsExpProg(params: any) {
        let result = [];
        try {
            const response: any = await fetch(`/api-py/get-budget-consolidate-expens-prog-gu/${encodeURI(params)}`);
            if (response.status === 200) {
                result = await response.json();
                if (result.status === 'success') {
                    result = result.data;
                } else {
                    const typeErr = (result.status === 'warning') ? 'warning': 'danger';
                    makeToast(this, typeErr, 'get-budget-consolidate-expens-prog-gu', result.statusText);
                    return null;
                }
            } else {
                makeToast(this, 'danger', 'Ошибка get-budget-consolidate-expens-prog-gu', `${response.status} ${response.statusText}`);
                return null;
            }
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка get-budget-consolidate-expens-prog-gu', (error as Error).toString());
            return null;
        }
        this.zeroDataDelete(result, 'expens-prog');
        this.progress+=25;
        return result;
    }

    /**
     * сформировать calcData
     * @param newMap
     * @param agreementMap
     * @param mapConsol
     * @param limitLst список лимитов
     */
    private getCalcData(newMap: Map<any, any>, agreementMap: Map<any, any>, mapConsol: Map<any, any>, limitMap: Map<any, any>, noteStatusMap: Map<string, any>) {
        const tmpTotal = [{ totalSum: { 'kass_ras': 0, 'fact_ras': 0, 'utoch_plan': 0, prog1: 0, prog2: 0, prog0: 0, limit0: 0, limit1: 0, limit2: 0 } }];
        const calcData: any[] = [];
        for (const [keyGu, valGu] of newMap) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            calcData.push({ gu: keyGu, name: null, vals: [], collapse: true, totalSum: { kass_ras: 0, fact_ras: 0, utoch_plan: 0, prog1: 0, prog2: 0, prog0: 0 } });
            const progArr: any[] = [];
            let flGuAgr = false; // true - есть записи с согласованием на уровне программы
            for (const [keyProg, valProg] of valGu) {
                const pprNullArr: any[] = [];
                // eslint-disable-next-line @typescript-eslint/camelcase
                progArr.push({ name: null, prg: keyProg, vals: [], collapse: true, totalSum: { kass_ras: 0, fact_ras: 0, utoch_plan: 0, prog1: 0, prog2: 0, prog0: 0 } });
                const subArr: any[] = [];
                let flProgAgr = false; // true - есть записи с согласованием на уровне подпрограммы
                for (const [keySub, valSub] of valProg) {
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    const tmp1: any = { name: null, ppr: keySub, vals: [], collapse: true, totalSum: { kass_ras: 0, fact_ras: 0, utoch_plan: 0, prog1: 0, prog2: 0, prog0: 0, agrObj: null } };
                    if (agreementMap.get(keyGu) && agreementMap.get(keyGu).get(keyProg) && agreementMap.get(keyGu).get(keyProg).get(keySub)) {
                        const tmpAgr = agreementMap.get(keyGu).get(keyProg).get(keySub);
                        const tmpAgr2 = { id: tmpAgr.id, status: tmpAgr.status.code, updateDate: tmpAgr.update_date, userId: tmpAgr.user_id, statusName: tmpAgr.status.name_ru, status_lang: {name_ru: tmpAgr.status.name_ru, name_kk: tmpAgr.status.name_kk, name_en: tmpAgr.status.name_en, availableStep: tmpAgr.availableStep}, commentTxt: tmpAgr.commentTxt };
                        if (tmpAgr.availableStep) { flProgAgr = true; }
                        if (keySub !== null) {
                            tmp1.agrObj = tmpAgr2;
                            if (tmpAgr.availableStep) { tmp1.checked = this.checkedAll; }
                        } else {
                            progArr[progArr.length -1].agrObj = tmpAgr2;
                            if (tmpAgr.availableStep) { progArr[progArr.length -1].checked = this.checkedAll; }
                        }
                    }
                    if (keySub!==null) { subArr.push(tmp1);}

                    for (const [key, value] of valSub) {
                        const obj: any = {};
                        const tmp = this.getSpfObj(value);
                        if (mapConsol.get(keyGu) && mapConsol.get(keyGu).get(keyProg) && mapConsol.get(keyGu).get(keyProg).get(keySub) && mapConsol.get(keyGu).get(keyProg).get(keySub).get(key)) {
                            const tmpCons = mapConsol.get(keyGu).get(keyProg).get(keySub).get(key);
                            obj.consExp = { 'cur_year': this.curYear, 'data_type': this.curDataType, gr: tmpCons.gr, abp: this.curAbp, prg: keyProg, ppr: keySub, spf: key, 'kass_ras': tmpCons.kass_ras, 'fact_ras': tmpCons.fact_ras, 'utoch_plan': tmpCons.utoch_plan, prog: tmpCons.prog, subProg: tmpCons.subProg, spfName: tmpCons.spfName, gu: tmpCons.gu, guName: tmpCons.guName, valueSourceLink: tmp.valueSourceLink };
                            mapConsol.get(keyGu).get(keyProg).get(keySub).delete(key);
                        } else {
                            obj.consExp = { 'cur_year': this.curYear, 'data_type': this.curDataType, gr: tmp.gr, abp: this.curAbp, prg: keyProg, ppr: keySub, spf: key, 'kass_ras': 0, 'fact_ras': 0, 'utoch_plan': 0, prog: tmp.prog, subProg: tmp.subProg, spfName: tmp.spfName, gu: tmp.gu, guName: tmp.guName, valueSourceLink: tmp.valueSourceLink };
                        }
                        for (const colName of Object.keys(value)) {
                            if (colName !== 'trash') {
                                let formBipMap = obj.formBipMap;
                                if (!formBipMap) { formBipMap = new Map() }
                                const resCalc = calcPrognWithForm(value[colName], key, formBipMap, colName);
                                obj[colName] = resCalc.val;
                                obj.formBipMap = resCalc.formBipMap;
                            }
                        }
                        this.getFormBipLst(obj);
                        if (obj.consExp.ppr !== null) {
                            subArr[subArr.length - 1].vals.push(obj);
                            this.calcSum(subArr, obj);
                            if (subArr[subArr.length - 1].name === null) { subArr[subArr.length - 1].name = obj.consExp.subProg; }
                        } else {
                            pprNullArr.push(obj);
                            this.calcSum(progArr, obj);
                        }

                        if (progArr[progArr.length - 1].name === null) { progArr[progArr.length - 1].name = obj.consExp.prog; }
                        if (!calcData[calcData.length - 1].name) { calcData[calcData.length - 1].name = obj.consExp.guName; }
                    }
                    if (subArr.length>0 && keySub !== null) { this.calcSum(progArr, subArr[subArr.length - 1].totalSum); }
                }
                if (flProgAgr) {
                    progArr[progArr.length - 1].checked = this.checkedAll;
                    flGuAgr = true;
                }
                progArr[progArr.length - 1].vals = subArr;
                if (pprNullArr.length) { progArr[progArr.length - 1].pprNullArr = pprNullArr; }
                this.calcSum(tmpTotal, progArr[progArr.length - 1].totalSum);
                this.calcSum(calcData, progArr[progArr.length - 1].totalSum);
            }
            calcData[calcData.length - 1].vals = progArr;
            if (flGuAgr) {
                calcData[calcData.length - 1].checked = this.checkedAll;
            }
        }
        // --- добавить оставшиейся с mapConsol
        for (const [keyGu, valGu] of mapConsol) {
            for (const [keyProg, valProg] of valGu) {
                for (const [keySub, valSub] of valProg) {
                    for (const [keySpf, valSpf] of valSub) {
                        const obj = { prog0: null, prog1: null, prog2: null, consExp: valSpf, formBip: null, formBipCollapse: null }
                        // -----
                        let flGu = false;
                        for (let i = 0; i < calcData.length; i++) {
                            const calcGu = calcData[i];
                            if (calcGu.gu === keyGu) {
                                flGu = true;
                                let flProg = false;
                                for (let p = 0; p < calcGu.vals.length; p++) {
                                    const calcProg = calcGu.vals[p];
                                    if (calcProg.prg === keyProg) {
                                        flProg = true;
                                        let flSub = false;
                                        for (let s = 0; s < calcProg.vals.length; s++) {
                                            const calcSub = calcProg.vals[s];
                                            if (calcSub.ppr === keySub) {
                                                flSub = true;
                                                calcSub.vals.push(JSON.parse(JSON.stringify(obj)));
                                                calcSub.vals.sort((a: any, b: any) => (a.consExp.spf > b.consExp.spf) ? 1 : -1);
                                                this.twoObjectsTotalSum(calcSub, obj.consExp);
                                                this.twoObjectsTotalSum(calcProg, obj.consExp);
                                                this.twoObjectsTotalSum(calcGu, obj.consExp);
                                                this.twoObjectsTotalSum(tmpTotal[0], obj.consExp);
                                                break;
                                            }
                                        }
                                        if (!flSub) {
                                            const totalSub = { fact_ras: valSpf.fact_ras, utoch_plan: valSpf.utoch_plan, kass_ras: valSpf.kass_ras, prog0: 0, prog1: 0, prog2: 0 }
                                            if (valSpf.ppr!==null) {
                                                const objSub = { agrObj: null, collapse: this.collapseAll, ppr: valSpf.ppr, name: valSpf.subProg, vals: [obj], totalSum: totalSub }
                                                calcProg.vals.push(JSON.parse(JSON.stringify(objSub)));
                                            } else {
                                                const pprNullObj = { prog0: 0, prog1: 0, prog2: 0, formBipCollapse: null, formBip: [], consExp: valSpf };
                                                if (!calcProg.pprNullArr) { calcProg.pprNullArr = []; }
                                                calcProg.pprNullArr.push(JSON.parse(JSON.stringify(pprNullObj)));
                                            }
                                            this.twoObjectsTotalSum(calcProg, obj.consExp);
                                            this.twoObjectsTotalSum(calcGu, obj.consExp);
                                            this.twoObjectsTotalSum(tmpTotal[0], obj.consExp);
                                        }
                                        break;
                                    }
                                    calcProg.vals.sort((a: any, b: any) => (a.ppr > b.ppr) ? 1 : -1);
                                }
                                if (!flProg) {
                                    const total = { fact_ras: valSpf.fact_ras, utoch_plan: valSpf.utoch_plan, kass_ras: valSpf.kass_ras, prog0: 0, prog1: 0, prog2: 0 }
                                    if (valSpf.ppr === null) {
                                        const pprNullObj = { prog0: 0, prog1: 0, prog2: 0, formBipCollapse: null, formBip: [], consExp: valSpf }
                                        const objProg = { collapse: this.collapseAll, prg: valSpf.prg, name: valSpf.prog, totalSum: total, vals: [], pprNullArr: [pprNullObj] };
                                        calcGu.vals.push(JSON.parse(JSON.stringify(objProg)));
                                        this.twoObjectsTotalSum(calcGu, obj.consExp);
                                        this.twoObjectsTotalSum(tmpTotal[0], obj.consExp);
                                    } else {
                                        const objSub = { agrObj: null, collapse: this.collapseAll, ppr: valSpf.ppr, name: valSpf.subProg, vals: [obj], totalSum: total }
                                        const objProg = { collapse: this.collapseAll, prg: valSpf.prg, name: valSpf.prog, totalSum: total, vals: [objSub] };
                                        calcGu.vals.push(JSON.parse(JSON.stringify(objProg)));
                                        this.twoObjectsTotalSum(calcGu, obj.consExp);
                                        this.twoObjectsTotalSum(tmpTotal[0], obj.consExp);
                                    }
                                }
                                calcData[i].vals.sort((a: any, b: any) => (a.prg > b.prg) ? 1 : -1);
                                break;
                            }
                        }
                        if (!flGu) {
                            const total = { fact_ras: valSpf.fact_ras, utoch_plan: valSpf.utoch_plan, kass_ras: valSpf.kass_ras, prog0: 0, prog1: 0, prog2: 0 }
                            let objProg = null;
                            if (valSpf.ppr === null) {
                                const pprNullObj = { prog0: 0, prog1: 0, prog2: 0, formBipCollapse: null, formBip: [], consExp: valSpf }
                                objProg = { collapse: this.collapseAll, prg: valSpf.prg, name: valSpf.prog, totalSum: total, vals: [], pprNullArr: [pprNullObj] };
                            } else {
                                const objSub = { agrObj: null, collapse: this.collapseAll, ppr: valSpf.ppr, name: valSpf.subProg, vals: [obj], totalSum: total }
                                objProg = { collapse: this.collapseAll, prg: valSpf.prg, name: valSpf.prog, totalSum: total, vals: [objSub] };
                            }
                            const guObj = { checked: null, collapse: this.collapseAll, gu: valSpf.gu, name: valSpf.guName, totalSum: total, vals: [ objProg ] }
                            calcData.push(JSON.parse(JSON.stringify(guObj)));
                            this.twoObjectsTotalSum(tmpTotal[0], obj.consExp);
                        }
                        // ----
                    }
                }
            }
        }
        // --- добавить статус заполняемости пояснительной записки
        for (let i = 0; i < calcData.length; i++) {
            const guEl = calcData[i];
            const guMap = noteStatusMap.get(guEl.gu);
            if (guMap) {
                for (const elPrg of guEl.vals) {
                    const prgNoteEl = guMap.get(elPrg.prg);
                    if (prgNoteEl) { 
                        elPrg.statusNote = { status: prgNoteEl.status, url: null };
                    }
                }
                noteStatusMap.delete(guEl.gu);
            }
            if (!noteStatusMap.size) { break; }
        }
        // ---

        this.totalSum = tmpTotal[0].totalSum;
        this.setLimit(calcData, limitMap);
        calcData.sort((a: any, b: any) => (a.gu > b.gu) ? 1 : -1);
        this.calcData = calcData;

        this.progress = 100;
        this.loadLimit();
    }

    // проставить лимиты
    private setLimit(calcData: any[], limitMap: Map<any, any>) {
        for (const guEl of calcData) {
            for (let i = 0; i < 3; i++) { guEl.totalSum[`limit${i}`] = 0; }
            const prgMap = limitMap.get(guEl.gu);
            for (const prgEl of guEl.vals) {
                if (prgMap) {
                    const limitEl = prgMap.get(prgEl.prg);
                    if (limitEl) {
                        for (let i = 0; i < 3; i++) {
                            prgEl[`limit${i}`] = limitEl[`limit${i}`];
                            guEl.totalSum[`limit${i}`] += limitEl[`limit${i}`];
                            this.totalSum[`limit${i}`] += limitEl[`limit${i}`];
                        }
                        limitMap.get(guEl.gu).delete(prgEl.prg);
                    } else {
                        for (let i = 0; i < 3; i++) { prgEl[`limit${i}`] = 0; }
                    }
                } else {
                    for (let i = 0; i < 3; i++) { prgEl[`limit${i}`] = 0; }
                }
            }
            if (prgMap) {
                if (prgMap.size) {
                    for (const [key, value] of limitMap.get(guEl.gu)) {
                        const totalSum = { kass_ras: 0, fact_ras: 0, utoch_plan: 0, prog1: 0, prog2: 0, prog0: 0 };
                        const obj: any = { collapse: null, name: value.prg.name_ru, prg: value.prg.prg, vals: [], totalSum: totalSum };
                        for (let i = 0; i < 3; i++) {
                            obj[`limit${i}`] = value[`limit${i}`];
                            guEl.totalSum[`limit${i}`] += value[`limit${i}`];
                            this.totalSum[`limit${i}`] += value[`limit${i}`];
                        }
                        guEl.vals.push(obj);
                    }
                    guEl.vals.sort((a: any, b: any) => (a.prg > b.prg) ? 1 : -1);
                }
                limitMap.delete(guEl.gu);
            }
        }
        if (limitMap.size) {
            for (const [keyGu, valueGu] of limitMap) {
                const objGu: any = { collapse: this.collapseAll, gu: keyGu, name: null, totalSum: {kass_ras: 0, fact_ras: 0, utoch_plan: 0, prog1: 0, prog2: 0, prog0: 0, limit0: 0, limit1: 0, limit2: 0},  vals: [] };
                for (const [keyPrg, valuePrg] of valueGu) {
                    if (objGu.name === null) { objGu.name = valuePrg.gu.name_ru; }
                    const totalSum = { kass_ras: 0, fact_ras: 0, utoch_plan: 0, prog1: 0, prog2: 0, prog0: 0 };
                    const objPrg = { collapse: null, limit0: valuePrg.limit0, limit1: valuePrg.limit1, limit2: valuePrg.limit2, prg: valuePrg.prg.prg, name: valuePrg.prg.name_ru, vals: [], totalSum: totalSum }
                    for (let i = 0; i < 3; i++) {
                        objGu.totalSum[`limit${i}`] += valuePrg[`limit${i}`];
                        this.totalSum[`limit${i}`] += valuePrg[`limit${i}`];
                    }
                    objGu.vals.push(objPrg);
                }
                objGu.vals.sort((a: any, b: any) => (a.prg > b.prg) ? 1 : -1);
                calcData.push(objGu);
            }
            calcData.sort((a: any, b: any) => (a.gu > b.gu) ? 1 : -1);
        }
    }

    private twoObjectsTotalSum(res: any, obj: any) {
        res.totalSum.fact_ras += obj.fact_ras;
        res.totalSum.utoch_plan += obj.utoch_plan;
        res.totalSum.kass_ras += obj.kass_ras;
    }

    private getFormBipLst(obj: any) {
        if (!obj.formBipMap) { return; }
        obj.formBip = [];
        for (const [key, value] of obj.formBipMap) {
            const formBipObj: any = {};
            for (const el of value) {
                const url = this.getUrl(obj, el);
                formBipObj[el.field] = { val: el.value, url: url };
                if (formBipObj.form === undefined || formBipObj.bipCode === undefined) {
                    formBipObj.form = el.form;
                    formBipObj.bipCode = el.bipCode;
                    formBipObj.bipName = el.bipName;
                    formBipObj.name = el.form;
                    formBipObj.valueSourceLink = el.valueSourceLink;
                    if (formBipObj.name===null) { formBipObj.name = el.bipCode; }
                }
            }
            obj.formBip.push(formBipObj);
        }
        obj.formBipCollapse = true;
        delete obj.formBipMap;
    }

    private getUrl(obj: any, el: any): string | null {
        if (el.valueSourceLink) {
            return `#/${el.valueSourceLink}`;
        }
        if (el.form!==null) {
            if (!obj.consExp) { return null; }
            let href: string | null = null;
            for (let i=0; i<formLst.length; i++) {
                if (formLst[i].code === el.form) {
                    href = formLst[i].href;
                    break;
                }
            }
            if (href === null) { href = `/form${el.form}`; }
            const year = parseInt(el.field[el.field.length-1]) + obj.consExp.cur_year;
            const params = `#${href}/${obj.consExp.cur_year}/${year}/${obj.consExp.data_type.code}/${obj.consExp.gr}/${this.curAbp.abp}/${obj.consExp.prg}/${obj.consExp.ppr}/${obj.consExp.gu}/${this.curVariant.variant_uuid}/${obj.consExp.spf}/no/gu/true/end`;
            return params;
        } else if (el.bipCode) {
            const params = `#/bip/bip-card?${encodeURI(`bip_code=${el.bipCode}&variant=${this.curVariant.variant_uuid}`)}`;
            return params;
        }
        return null;
    }

    private async loadTotalAgreement() {
        if (!this.operationCode.length) { await this.loadOperations(); }
        const params = JSON.stringify({ curYear: parseInt(this.curYear.toString()), abp: this.curAbp.abp, dataType: this.curDataType.code, region: this.region, variant: this.curVariant.variant_uuid, mode_code: this.modeCode, operation_code: this.operationCode, sign_operation_code: this.operationSignCode, gu: (this.curGu? this.curGu.code: null) });
        let result = null;
        try {
            const response: any = await fetch(`/api-py/get-budget-total-agreement-gu/${encodeURI(params)}`);
            if (response.status === 200) {
                result = await response.json();
                this.signOperEnable = this.checkOperationCode(this.signOperation, result);
            } else {
                makeToast(this, 'danger', 'Ошибка get-budget-total-agreement',  `${response.status} - ${response.statusText}`);
                return new Map();
            }
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка get-budget-total-agreement', (error as Error).toString());
            return new Map();
        } finally {
            this.chgSignAcces();
        }
        return this.createTreeMap(result, true, true);
    }

    // проверить есть ли код операций во всех кодах согласования
    private checkOperationCode(operCode: string, agrArr: any[]) {
        if (!agrArr || !agrArr.length) { return false; }
        for (const el of agrArr) {
            let fl = false;
            for (let i = 0; i < el.operation_codes.length; i++) {
                const opEl = el.operation_codes[i];
                if (opEl === operCode) {
                    fl = true;
                    break;
                }
            }
            if (!fl) { return false; }
        }
        return true;
    }

    private async loadLimit() {
        let result = null;
        let sum = 0;
        for (const el of this.calcData) {
            sum += el.totalSum.prog0;
        }
        this.limit = { sum: sum.toFixed(2), limit: 0 };
        const params = { year: parseInt(this.curYear.toString()) + 1, abp: this.curAbp.abp, region: this.region };
        try {
            const response: any = await fetch(`/api-py/get-sum-calc-total-limit/${encodeURI(JSON.stringify(params))}`);
            if (response.status === 200) {
                result = await response.json();
                if (result.limit) {
                    this.limit.limit = result.limit.toFixed(2);
                }
            } else {
                makeToast(this, 'danger', 'Ошибка get-sum-calc-total-limit', `${response.status} - ${response.statusText}`);
            }
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка get-sum-calc-total-limit', (error as Error).toString());
            this.progress = 0;
        }
    }
    // -----------------------
    // ---notSpf - без специфики
    private createTreeMap(arr: any[], consolidate: boolean, notSpf?: boolean) {
        const resMap = new Map();
        for (const el of arr) {
            let tmp = resMap.get(el.gu);
            if (tmp) {
                tmp.push(el);
            } else {
                tmp = [el];
            }
            resMap.set(el.gu, tmp);
        }
        for (const [keyRes, valueRes] of resMap) {
            const progMap = new Map();
            for (const el of valueRes) {
                let tmp = progMap.get(el.prg);
                if (tmp) {
                    tmp.push(el);
                } else {
                    tmp = [el];
                }
                progMap.set(el.prg, tmp);
            }
            for (const [key, value] of progMap) {
                const subProg = new Map();
                for (const el of value) {
                    let tmp = subProg.get(el.ppr);
                    if (tmp) {
                        tmp.push(el);
                    } else {
                        tmp = [el];
                    }
                    subProg.set(el.ppr, tmp);
                }
                for (const [keySub, valSub] of subProg) {
                    const spfMap = new Map();
                    if (!notSpf) {
                        if (consolidate) {
                            for (const el of valSub) {
                                spfMap.set(el.spf, el);
                            }
                        } else {
                        // --------------------
                            for (const el of valSub) {
                                const fieldName = this.getSumFieldName(el.year);
                                const findObj = spfMap.get(el.spf);
                                if (findObj === undefined) {
                                    const newObj: any = { prog0: new Map(), prog1: new Map(), prog2: new Map(), trash: new Map() };
                                    newObj[fieldName].set(el.form, [el]);
                                    // newObj[fieldName].set(el.form, el);
                                    spfMap.set(el.spf, newObj);
                                } else if (findObj[fieldName]) {
                                    let tmpFind = findObj[fieldName].get(el.form);
                                    if (!tmpFind) { tmpFind = []; }
                                    tmpFind.push(el);
                                    findObj[fieldName].set(el.form, tmpFind);
                                    spfMap.set(el.spf, findObj);
                                }
                            }
                        }
                        subProg.set(keySub, spfMap);
                    } else {
                        if (valSub.length > 0) { subProg.set(keySub, valSub[0]); }
                    }

                }
                progMap.set(key, subProg);
            }
            resMap.set(keyRes, progMap);
        }
        return resMap;
    }

    // получение имени поля прогноза (trash - не подходящий год)
    private getSumFieldName(year: number): string {
        if (year - this.curYear < 0 || year - this.curYear > 3) { return 'trash'; }
        return `prog${(year - this.curYear)}`;
    }

    private clkCollapse(calcIndx: number | null, progIndx: number | null, subIndx: number | null, collapse?: boolean) {
        if (calcIndx === null) {
            this.collapseAll = !this.collapseAll;
            for (const el of this.calcData) {
                el.collapse = this.collapseAll;
                for (const progEl of el.vals) {
                    if (progEl.collapse!==null) { progEl.collapse = this.collapseAll; }
                    for (const subEl of progEl.vals) {
                        subEl.collapse = this.collapseAll;
                    }
                }
            }
            return;
        }
        if (progIndx === null) {
            this.calcData[calcIndx].collapse = collapse;
            for (const el of this.calcData[calcIndx].vals) {
                if (el.collapse!==null) { el.collapse = collapse; }
            }
        } else {
            if (subIndx === null) {
                this.calcData[calcIndx].vals[progIndx].collapse = collapse;
                for (const el of this.calcData[calcIndx].vals[progIndx].vals) {
                    el.collapse = collapse;
                }
            } else {
                this.calcData[calcIndx].vals[progIndx].vals[subIndx].collapse = collapse;
            }
        }
    }

    private commentClk(calcIndx: number, progIndx: number, subIndx: number | null) {
        let agrObj: any = null;
        if (subIndx!==null) {
            agrObj = this.calcData[calcIndx].vals[progIndx].vals[subIndx].agrObj;
        } else {
            agrObj = this.calcData[calcIndx].vals[progIndx].agrObj;
        }

        // eslint-disable-next-line
        // @ts-ignore
        this.$refs.refHistModal.showEvent(agrObj);
    }

    private getSpfObj(spfVal: any) {
        for (const colName of Object.keys(spfVal)) {
            if (spfVal[colName].size > 0 && spfVal[colName].entries().next().value[1].length) {
                return spfVal[colName].entries().next().value[1][0];
            }
        }
        return {};
    }

    // -------------- checked -------------------------------------------
    private clkCheck(calcIndx: number | null, progIndx: number | null, subIndx: number | null) {
        if (calcIndx === null) {
            for (const el of this.calcData) {
                if (el.checked !== undefined) {
                    el.checked = this.checkedAll;
                    this.checkAllChilds(this.checkedAll, el);
                 }
            }
            return;
        }
        let checked = this.calcData[calcIndx].checked;
        if (progIndx === null) {
            this.checkAllChilds(checked, this.calcData[calcIndx]);
        } else {
            if (subIndx === null) {
                checked = this.calcData[calcIndx].vals[progIndx].checked;
                this.checkAllChilds(checked, this.calcData[calcIndx].vals[progIndx]);
                if (!checked) {
                    this.calcData[calcIndx].checked = false;
                } else {
                    let fl = true;
                    for (let i =0; i < this.calcData[calcIndx].vals.length; i++) {
                        const el = this.calcData[calcIndx].vals[i];
                        if (el.checked === false) {
                            fl = false;
                            break;
                        }
                    }
                    this.calcData[calcIndx].checked = fl;
                }
            } else {
                checked =this.calcData[calcIndx].vals[progIndx].vals[subIndx];
                if (!checked) {
                    this.calcData[calcIndx].vals[progIndx].checked = false;
                } else {
                    let fl = true;
                    for (let i =0; i < this.calcData[calcIndx].vals[progIndx].vals.length; i++) {
                        const el = this.calcData[calcIndx].vals[progIndx].vals[i];
                        if (el.checked === false) {
                            fl = false;
                            break;
                        }
                    }
                    this.calcData[calcIndx].vals[progIndx].checked = fl;
                }
                if (!this.calcData[calcIndx].vals[progIndx].checked) {
                    this.calcData[calcIndx].checked = false;
                } else {
                    let fl = true;
                    for (let i =0; i < this.calcData[calcIndx].vals.length; i++) {
                        const el = this.calcData[calcIndx].vals[i];
                        if (el.checked === false) {
                            fl = false;
                            break;
                        }
                    }
                    this.calcData[calcIndx].checked = fl;
                }
            }
        }

        for (const el of this.calcData) {
            if (el.checked === false) {
                this.checkedAll = false;
                return;
            }
        }
        this.checkedAll = true;

    }

    // checked проставить для дочерних
    private checkAllChilds(checked: boolean, obj: any) {
        if (obj.vals) {
            for (const el of obj.vals) {
                if (el.checked!==undefined) {
                    el.checked = checked;
                    this.checkAllChilds(checked, el);
                }
            }
        }

    }

    // ------------------- end checked ----------------------

    private calcSum(sumArr: any[], obj: any) {
        // eslint-disable-next-line @typescript-eslint/camelcase
        let consObj = obj;
        if (obj.consExp) { consObj = obj.consExp; }
        // eslint-disable-next-line @typescript-eslint/camelcase
        if (consObj.kass_ras) { sumArr[sumArr.length - 1].totalSum.kass_ras += consObj.kass_ras; }
        // eslint-disable-next-line @typescript-eslint/camelcase
        if (consObj.fact_ras) { sumArr[sumArr.length - 1].totalSum.fact_ras += consObj.fact_ras; }
        // eslint-disable-next-line @typescript-eslint/camelcase
        if (consObj.utoch_plan) { sumArr[sumArr.length - 1].totalSum.utoch_plan += consObj.utoch_plan; }
        if (obj.prog1) { sumArr[sumArr.length - 1].totalSum.prog1 += obj.prog1; }
        if (obj.prog2) { sumArr[sumArr.length - 1].totalSum.prog2 += obj.prog2; }
        if (obj.prog0) { sumArr[sumArr.length - 1].totalSum.prog0 += obj.prog0; }
    }

    // кнопка действия
    private async agreementEvent() {
        const selectArr: any = [];
        this.selectArr = [];
        this.agrBtnLst = { back: [], forward: [] };
        let firstStatus: number | null = null;
        for (const calc of this.calcData) {
            for (const prog of calc.vals) {
                if (prog.pprNullArr && prog.pprNullArr.length && prog.checked && prog.agrObj) { // без подпрограммы
                    if (firstStatus === null) { firstStatus = prog.agrObj.status; }
                    if (firstStatus !== prog.agrObj.status) {
                        makeToast(this, 'danger', 'Согласование', 'Выберите подпрограммы с одинаковым статусом!');
                        return;
                    }
                    selectArr.push({ consExp: prog.pprNullArr[0].consExp, agrObj: prog.agrObj });
                }
                for (const sub of prog.vals) {
                    if (sub.checked && sub.vals.length && sub.agrObj) {
                        if (firstStatus === null) { firstStatus = sub.agrObj.status; }
                        if (firstStatus !== sub.agrObj.status) {
                            makeToast(this, 'danger', 'Согласование', 'Выберите подпрограммы с одинаковым статусом!');
                            return;
                        }
                        selectArr.push({ consExp: sub.vals[0].consExp, agrObj: sub.agrObj })
                    }
                }
            }
        }
        if (selectArr.length === 0) {
            makeToast(this, 'warning', 'Согласование', 'Не выбрано ни одной программы/подпрограммы!');
            return;
        }
        this.selectArr = selectArr;
        // ---------------
        const params = { modeCode: this.modeCode, operationCode: this.operationCode, agrCode: firstStatus };

        let result: any = null;
        try {
            const response: any = await fetch(`/api-py/get-agreement-step-next-back/${encodeURI(JSON.stringify(params))}`);
            result = await response.json();
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка get-agreement-step-next-back', (error as Error).toString());
            this.progress = 0;
            return;
        }
        if (result.length === 0) {
            makeToast(this, 'warning', 'Согласование', 'Нет доступных шагов согласования!');
            return;
        }
        for (const el of result) {
            let name = el[`name_${this.$i18n.locale}`];
            if (!name) { name = el.name_ru; }
            el.name = name;
            if (el.stepType === 1) {
                this.agrBtnLst.back.push(el);
            } else {
                this.agrBtnLst.forward.push(el);
            }
        }
        // eslint-disable-next-line
        // @ts-ignore
        this.$refs.refAgrModal.showEvent();
    }

    private async agrClk(data: any) {
        data.userId = store.state.user.sub;
        const saveArr = [];
        const guMap = new Map();
        for (const el of this.selectArr) {
            guMap.set(el.consExp.gu, {gu: el.consExp.gu})
            saveArr.push(Object.assign({ id: el.agrObj.id }, data));
        }
        const guArr = [];
        for (const [key, val] of guMap) {
            guArr.push(key);
        }
        const saveObj = {
            signOperation: this.signOperation,
            modeCode: this.modeCode,
            variant: this.curVariant.variant_uuid,
            abp: this.curAbp.abp,
            gu: guArr,
            cur_year: this.curYear,
            data_type: this.curDataType.code,
            region: this.curRegion.code,
            userId: this.usrId,
            items: saveArr,
        };

       let result: any = await fetch('/api-py/set_brft_agreement_step_gu',
            {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify(saveObj)
            });
        if (result.status === 200) {
            result = await result.json();
            for (const res of result) {
                if (res.result === 'error') {
                    makeToast(this, 'danger', 'Сохранение status', `${res.errTxt}`);
                    console.error('Ошибка set_brft_agreement_step_gu', res);
                    return;
                }
            }
            this.loadData();
        } else {
            makeToast(this, 'danger', 'Сохранение status', `Ошибка ${result.status} ${result.statusText}`);
            return;
        }
    }

    /**
     * загрузка данных пользователя по usrId
     */
     private async loadUserName(usrId: string) {
        let result = null;
        try {
            const response: any = await fetch(`/api-py/get-realm-user/${encodeURI(usrId)}`);
            if (response.status === 200) {
                result = await response.json();
            } else {
                makeToast(this, 'danger', 'Подписание', `Ошибка ${response.status}`);
                console.error(response);
                return null;
            }
        } catch (error) {
            makeToast(this, 'danger', 'loadUserName', (error as Error).toString());
            result = null;
        } finally {
            return result;
        }
    }

    // ----------------- Подписи --------------
    /**
     * загрузка всех подписей и подписантов
     */
     private async loadSign() {
        const result = await Promise.all([
            this.loadUserSignEnable(),
            this.loadDataSign()
        ]);
        this.chgSignAcces();
        await this.getAllSignUsers(result[0]);
        await this.getAllSignUsers(result[1]);
    }

    /**
     * загрузка всех подписантов
     */
     private async loadUserSignEnable() {
        this.userSignEnable = false;
        if (!this.curGu || !this.curGu.code) { return []; }
        const params = JSON.stringify({ abp: this.curAbp.abp, region: this.region, gu: this.curGu.code, codeModule: ['004.002.002.002'], date_b: this.curVariant.date_start, date_e: this.variantEndDate });
        let result = [];
        try {
            const response: any = await fetch(`/api-py/get-budget-user-sign-enable/${encodeURI(params)}`);
            result = await response.json();
            for (const el of result) {
                // --
                let txt = el.position[`name_${this.$i18n.locale}`];
                if (!txt) { txt = el.position.name_ru; }
                el.positionName = txt;
                // --
                el.create_date = null;
                if (el.user_id === this.usrId) { this.userSignEnable = true; }
            }
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка get-budget-user-sign-enable', (error as Error).toString());
            result = [];
        } finally {
            return result;
        }
    }

    // получить имена и логины пользователей
    private async getUserLoginName(el: any) {
        const obj = { name: '', userName: '' };
        if (el.name || el.userName) { return { name: el.name, userName: el.userName } }
        const result = await this.loadUserName(el.user_id);
        if (result) {
            obj.name = (result.firstName ? `${result.firstName} `: '') + (result.lastName ? result.lastName: '');
            obj.userName = result.username;
        }
        return obj;
    }

    /**
     * получение списка всех подписантов вместе с текущими подписями
     * @param userLst
     */
    private async getAllSignUsers(userLst: any[]) {
        for (const el of userLst) {
                let fl = false;
                for (let i = 0; i < this.signUserLst.length; i++) {
                    const elSign = this.signUserLst[i];
                    if (elSign.user_id === el.user_id) {
                        fl = true;
                        if (el.create_date) { elSign.create_date = el.create_date; }
                        if (!elSign.name || !elSign.userName) {
                            const nameRes = await this.getUserLoginName(el);
                            elSign.name = nameRes.name;
                            elSign.userName = nameRes.userName;
                        }
                        break;
                    }
                }
                if (!fl) {
                    const nameRes = await this.getUserLoginName(el);
                    el.name = nameRes.name;
                    el.userName = nameRes.userName;
                    if (!el.positionName) { el.positionName = ''; }
                    this.signUserLst.push(el);
                }
        }
        // ---- установка статуса подписания
        let signed = false;
        let notSigned = false;
        for (const el of this.signUserLst) {
            if (el.create_date) {
                signed = true;
            } else {
                notSigned = true;
            }
        }
        if (!signed && notSigned) {
            this.signStatus = { id: 0, name: 'Не подписан' };
        } else if (signed && notSigned) {
            this.signStatus = { id: 1, name: 'Частично подписан' };
        } else if (signed && !notSigned) {
            this.signStatus = { id: 2, name: 'Полностью подписан' };
        }
        // ----
    }

    /**
     * загрузка всех подписей по этим данным
     */
    private async loadDataSign() {
        if (!this.curGu || !this.curGu.code) { return []; }
        for (const el of this.signUserLst) {
            el.create_date = null;
        }
        const params = JSON.stringify({
            variant: this.curVariant.variant_uuid,
            abp: this.curAbp.abp,
            gu: this.curGu.code,
            cur_year: this.curYear,
            data_type: this.curDataType.code,
            region: this.curRegion.code,
            deleted: false
        });
        let result: any[] = [];
        try {
            let response: any = await fetch(`/api-py/get-budget_request_form_total_sign/${encodeURI(params)}`);
            response = await response.json();

            for (const el of response) {
                el.create_date = this.dateFormat(new Date(el.create_date));
                result.push({ user_id: el.user_id, create_date: el.create_date });
                if (el.user_id === this.usrId) { this.curUserSigned = true; }
            }
            if (this.curUserSigned === null) { this.curUserSigned = false; }
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка get-budget_request_form_total_sign', (error as Error).toString());
            result = [];
        } finally {
            return result;
        }
    }


    private chgSignAcces() {
        this.$emit('chgSignAcces', { signOperEnable: this.signOperEnable, userSignEnable: this.userSignEnable, curUserSigned: this.curUserSigned });
    }

    private startSignClk() {
        // eslint-disable-next-line
        // @ts-ignore
        this.$refs.refUserStartSignModal.showEvent();
    }

    /**
     * подписать
     */
    private async startSign() {
        if (!this.curGu || !this.curGu.code) {
            makeToast(this, 'warning', 'Подписание', 'Для подписания выберите ГУ!');
            return;
        }
        if (!this.usrName || this.usrName.length < 3) {
            makeToast(this, 'warning', 'Подписание', 'Не удалось получить ИИН пользователя!');
            console.error('Не удалось получить ИИН пользователя!', this.usrName, this.usrId)
            return;
        }
        const iin = this.usrName.slice(3);
        const obj = {
            variant: this.curVariant.variant_uuid,
            abp: this.curAbp.abp,
            gu: this.curGu.code,
            cur_year: this.curYear,
            data_type: this.curDataType.code,
            region: this.curRegion.code,
            val0: this.totalSumWithoutBip.prog0,
            val1: this.totalSumWithoutBip.prog1,
            val2: this.totalSumWithoutBip.prog2
        };

        const hash: string = await CryptoJS.SHA256(JSON.stringify(obj)).toString();
        let response = null;
        try {
            response = await NCAlayerService.signData(hash, { iin: iin });
            if (response.error) {
                makeToast(this, 'danger', 'Ошибка подписания', response.error);
                return;
            }
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка подписания', (error as Error).toString());
            return;
        }
        if (!response.result) { return; }
        const saveObj = Object.assign({
            hash_txt: hash,
            sign_txt: response.result,
            user_id: this.usrId,
            signType: 'gu'
        }, obj);
        let result: any = await fetch('/api-py/save-budget_request_form_total_sign',
            {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify(saveObj)
            });
        if (result.status === 200) {
            result = await result.json();
            if (result.result === 'success') {
                makeToast(this, 'success', 'Подписание', 'Данные подписаны');
            } else {
                makeToast(this, 'danger', 'Подписание', result.errTxt);
            }
            // --
            const dtSign = await this.loadDataSign();
            this.chgSignAcces();
            await this.getAllSignUsers(dtSign);
            // --
        } else {
            makeToast(this, 'danger', 'Подписание', `Ошибка ${result.status} ${result.statusText}`);
            return;
        }
    }

    private showUserSignModal() {
        // eslint-disable-next-line
        // @ts-ignore
        this.$refs.refUserSignModal.showEvent(this.signUserLst);
    }

    private recalSignModal() {
        // eslint-disable-next-line
        // @ts-ignore
        this.$refs.refUserSignRecalModal.showEvent();
    }

    /**
     * открыть Журнал
     */
    private logSign() {
        if (!this.curGu || !this.curGu.code) { return; }
        const params = {
            variant: this.curVariant.variant_uuid,
            abp: this.curAbp.abp,
            gu: this.curGu.code,
            cur_year: this.curYear,
            data_type: this.curDataType.code,
            region: this.curRegion.code,
            deleted: null
        };
        // eslint-disable-next-line
        // @ts-ignore
        this.$refs.refUserSignLogModal.showEvent(params);
    }

    /**
     * отозвать подпись
     * @param commentTxt
     */
    private async recalClk(commentTxt: string) {
        const saveObj = {
            variant: this.curVariant.variant_uuid,
            abp: this.curAbp.abp,
            gu: this.curGu.code,
            cur_year: this.curYear,
            data_type: this.curDataType.code,
            region: this.curRegion.code,
            user_id: this.usrId,
            comment_txt: commentTxt
        }

        let result: any = await fetch('/api-py/recal-gu-budget_request_form_total_sign',
            {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify(saveObj)
            });
        if (result.status === 200) {
            result = await result.json();
            if (result.result === 'success') {
                makeToast(this, 'success', 'Отзыв подписи', 'Подпись отозвана');
            } else {
                console.error('Отзыв подписи', result)
                makeToast(this, 'danger', 'Отзыв подписи', result.errTxt);
            }
            this.curUserSigned = null;
            // --
            const dtSign = await this.loadDataSign();
            this.chgSignAcces();
            await this.getAllSignUsers(dtSign);
            // --
        } else {
            makeToast(this, 'danger', 'Отзыв подписи', `Ошибка ${result.status} ${result.statusText}`);
            return;
        }
    }

    // ---------------------------------------

    private dateFormat(date: Date): string {
        return new Intl.DateTimeFormat(this.$i18n.locale, {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        }).format(date);
    }
}
